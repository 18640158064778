define(['app'], (app) => {

  const subscribeAndSaveProductInBasket = () => {
    const DATA_SELECTED = 'data-selected';
    const component = {};

    component.config = {
      selectors: {
        oneTimePurchaseMessage: '.subscribeAndSaveProductInBasket_text_otpInBasket',
        subscriptionMessage: '.subscribeAndSaveProductInBasket_text_subscriptionInBasket',
        noContractsMessage: '.subscribeAndSaveProductInBasket_text_noContractsAvailable'
      },
      classes: {
        showContainer: 'subscribeAndSaveProductInBasket_show',
      },
      subscribeChannels: {
        showMessage: 'subscribeAndSaveProductInBasket/showMessage',
        tabChanges: 'subscribeAndSaveProductInBasket/tabChanges',
        noContracts: 'subscribeAndSaveProductInBasket/noContracts',
        disableBasket: 'productAddToBasket/disableBasket'
      },
      attributes: {
        purchaseMethod: 'data-purchase-method'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.purchaseMethod = element.getAttribute(component.config.attributes.purchaseMethod);
      component.isSubscriptionTab = false;
      component.oneTimePurchaseMessage = element.querySelector(component.config.selectors.oneTimePurchaseMessage);
      component.subscriptionMessage = element.querySelector(component.config.selectors.subscriptionMessage);
      component.noContractsMessage = element.querySelector(component.config.selectors.noContractsMessage);
      component.subscribeToChannels();

      component.updatePurchaseMethodMessage(component.isSubscriptionTab);
    };

    component.updatePurchaseMethod = (purchaseMethod) => {
      if (purchaseMethod) {
        component.purchaseMethod = purchaseMethod;
      } else {
        component.purchaseMethod = '';
      }
      component.updatePurchaseMethodMessage(component.isSubscriptionTab);
    };

    // updates message based on what tab the user is on and the purchase method
    component.updatePurchaseMethodMessage = (isSubscriptionTab) => {
      component.isSubscriptionTab = isSubscriptionTab;

      if ((isSubscriptionTab && component.purchaseMethod === 'otp')
        ||(!isSubscriptionTab && component.purchaseMethod === 'subscription')) {
        component.showPurchaseMethodMessage(isSubscriptionTab);
      } else {
        component.hidePurchaseMethodMessage();
      }
    };

    component.showPurchaseMethodMessage = (isSubscriptionTab) => {
      component.oneTimePurchaseMessage.setAttribute(DATA_SELECTED, isSubscriptionTab);
      component.subscriptionMessage.setAttribute(DATA_SELECTED, !isSubscriptionTab);
      component.noContractsMessage.setAttribute(DATA_SELECTED, false);
      component.element.classList.add(component.config.classes.showContainer);

      app.publish(component.config.subscribeChannels.disableBasket, true);
    };

    component.hidePurchaseMethodMessage = () => {
      component.oneTimePurchaseMessage.setAttribute(DATA_SELECTED, false);
      component.subscriptionMessage.setAttribute(DATA_SELECTED, false);

      component.publishToDisableAddToBasket();
    };

    component.updateNoContractsAvailable = (noContracts) => {
      component.noContractsAvailable = noContracts;
      component.publishToDisableAddToBasket();
    };

    component.publishToDisableAddToBasket = () => {
      let disableBasket = component.isSubscriptionTab  && component.noContractsAvailable;
      component.noContractsMessage.setAttribute(DATA_SELECTED, disableBasket);

      disableBasket ? component.element.classList.add(component.config.classes.showContainer) :
        component.element.classList.remove(component.config.classes.showContainer);

      app.publish(component.config.subscribeChannels.disableBasket, disableBasket);
    };

    component.subscribeToChannels = () => {
      app.clear(component.config.subscribeChannels.showMessage);
      app.clear(component.config.subscribeChannels.tabChanges);
      app.clear(component.config.subscribeChannels.noContracts);
      app.subscribe(component.config.subscribeChannels.showMessage, component.updatePurchaseMethod);
      app.subscribe(component.config.subscribeChannels.tabChanges, component.updatePurchaseMethodMessage);
      app.subscribe(component.config.subscribeChannels.noContracts, component.updateNoContractsAvailable);
    };

    return component;
  };

  return subscribeAndSaveProductInBasket;
});
